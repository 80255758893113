import React from 'react';
import Img from "gatsby-image";
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby";
import {useIntl} from "react-intl";

const Experience = ({value}) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});
    const images = useStaticQuery(graphql`
    query {
      citelis: file(relativePath: { eq: "companies/citelis-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      citelisDark: file(relativePath: { eq: "companies/citelis-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      diDark: file(relativePath: { eq: "companies/di.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      di: file(relativePath: { eq: "companies/di-logo-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      setenal: file(relativePath: { eq: "companies/setenal-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      setenalDark: file(relativePath: { eq: "companies/setenal.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ee: file(relativePath: { eq: "companies/ee.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eeDark: file(relativePath: { eq: "companies/ee-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cave: file(relativePath: { eq: "companies/agendacave.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caveDark: file(relativePath: { eq: "companies/cave.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marista: file(relativePath: { eq: "companies/maristas.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maristaDark: file(relativePath: { eq: "companies/maristasDark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <motion.div
            className="item-panel-wrapper flex gap-24 md:gap-24 p-6 md:p-14 items-center h-full"
            drag="x"
            dragConstraints={{left: -value, right: 0}}
            initial={{opacity: 0.8, x: 1000}}
            animate={{opacity: 1, x: 0, transition: {duration: 1.0}}}
        >
            <div className="card">
                <div className="ellipse1"/>
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 180}}
                        fluid={images.citelisDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle1")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle1")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription1")}</p>
                </div>
            </div>

            <div className="card">
                <div className="ellipse2"/>
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 60}}
                        fluid={images.diDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle2")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle2")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription2")}</p>
                </div>
            </div>

            <div className="card">
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 160}}
                        fluid={images.setenalDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle3")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle3")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription3")}</p>
                </div>
            </div>


            <div className="card">
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 180}}
                        fluid={images.eeDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle4")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle4")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription4")}</p>
                </div>
            </div>

            <div className="card">
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 100}}
                        fluid={images.caveDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle5")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle5")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription5")}</p>
                </div>
            </div>

            <div className="card">
                <div
                    className="glass-morphism-cards flex flex-col justify-center items-center md:items-end py-5 px-2 md:p-5 shadow-xl dark:shadow-none">
                    <Img
                        style={{height: 80, width: 180}}
                        fluid={images.maristaDark.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                    <p className='font-Bold leading-relaxed text-center md:text-right text-white'>{f("cardTitle6")}</p>
                    <p className='font-Regular text-center md:text-right text-white'>{f("cardSubTitle6")}</p>
                    <p className="font-LightItalic leading-loose text-center md:text-right text-white">{f("cardDescription6")}</p>
                </div>
            </div>
        </motion.div>
    );
};

export default Experience;
