import * as React from "react";
import {useIntl} from "react-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Loader from "../components/loader";
import Experience from "../components/experience";
import educationDark from "../images/educationDark.svg";
import education from "../images/education.svg";
import {useColorMode} from "theme-ui";
import waveDark from "../images/waveDark.svg";
import wave from "../images/wave.svg";
import ReactTooltip from 'react-tooltip';
import RESUME from "../resources/resume.pdf"
import {useLocalization} from "gatsby-theme-i18n";

const ResumePage = (props) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const {locale} = useLocalization()

    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return (<Loader/>)
    }

    return (
        <Layout location={props.location} color='#00CC88'>
            <SEO title={f("resume")} lang={locale} description={f("descriptionMeta")}/>
            <div className='flex flex-col justify-evenly md:items-center py-5 px-6 xl:px-14 xl:py-36 gap-4' style={{
                backgroundImage: `url(${isDark ? waveDark : wave})`,
                backgroundBlendMode: "overlay",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <div className='flex flex-col gap-2'>
                    <p className='font-ExtraBold text-5xl xl:text-6xl text-left md:text-center'>
                        {f("resume")}<span className='text-gray-light'>.</span>
                    </p>
                    <p className='font-Regular text-lg xl:text-xl leading-relaxed xl:leading-loose text-left md:text-center mb-12'>
                        {f("thanks")}
                    </p>
                </div>
                <div className='grid md:grid-cols-2 gap-4 md:gap-28'>
                    <div className='flex md:justify-end'>
                        <img src={isDark ? educationDark : education} alt='Education'
                             className='h-auto w-4/5 md:w-1/2'/>
                    </div>
                    <div className='flex flex-col gap-4 md:w-2/3'>
                        <p className='font-ExtraBold text-2xl leading-relaxed md:leading-loose text-left'>
                            {f("education")}
                        </p>
                        <div className='flex flex-col gap-2 md:gap-1'>
                            <p className='font-Medium leading-relaxed xl:leading-loose text-left'>
                                {f("education1")}
                            </p>
                            <p className='font-ExtraLight leading-relaxed xl:leading-loose text-left'>
                                {f("school1")}
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 md:gap-1'>
                            <p className='font-Medium  leading-relaxed xl:leading-loose text-left'>
                                {f("education2")}
                            </p>
                            <p className='font-ExtraLight leading-relaxed xl:leading-loose text-left'>
                                {f("school2")}
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 md:gap-1'>
                            <p className='font-Medium  leading-relaxed xl:leading-loose text-left'>
                                {f("education3")}
                            </p>
                            <p className='font-ExtraLight leading-relaxed xl:leading-loose text-left'>
                                {f("school3")}
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 md:gap-1'>
                            <div className='h-8 w-8 cursor-pointer'
                                 data-for="main"
                                 data-tip={f("downloadPdf")}
                                 data-iscapture="true">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                </svg>
                            </div>

                            <ReactTooltip
                                id="main"
                                place="right"
                                type={isDark ? "light" : "dark"}
                                effect="float"
                                multiline={true}
                                className='font-Medium mx-2'
                                border={false}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="overflow-hidden bg-gray flex flex-col justify-evenly md:justify-center h-540px md:h-540px relative mt-8">
                <div className='flex flex-col md:flex-row justify-between px-6 md:px-14'>
                    <p className='font-ExtraBold text-5xl xl:text-6xl text-white'>
                        {f("experience")}<span className='text-gray-light'>.</span>
                    </p>
                </div>
                <div className='block md:hidden'>
                    <Experience value={2080}/>
                </div>
                <div className='hidden md:block xl:hidden'>
                    <Experience value={1860}/>
                </div>
                <div className='hidden md:hidden xl:block'>
                    <Experience value={1780}/>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-8 md:gap-24 px-6 py-24 md:px-14 md:py-24">
                <div className='flex flex-col gap-2 justify-center items-center'>
                    <a href={RESUME} download target="_blank" rel="noreferrer"
                       className='flex flex-col gap-4 justify-center items-center cursor-pointer'>
                        <div className='h-14 w-14 md:h-32 md:w-32 text-green relative'>
                        <span
                            className="animate-ping inline-flex h-1.5 w-1.5 md:h-3 md:w-3 rounded-full bg-green top-1 md:top-2 right-2 md:right-5 absolute opacity-75"/>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                            </svg>
                        </div>
                        <span className='font-Medium text-lg'>{f("download")}</span>
                    </a>
                    <p className='font-LightItalic text-center leading-relaxed xl:leading-loose'>{f("downloadDescription")}</p>
                </div>
            </div>
        </Layout>
    );
};

export default ResumePage;
